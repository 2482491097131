import axiosInstance from "./Api";

export default {
  async SignIn(payload) {
    let response = await axiosInstance().post(`/auth/login`, payload);
    return response;
  },

  async RestLink(payload) {
    let response = await axiosInstance().post(`/auth/reset_link`, payload);
    return response;
  },

  async UpdatePassword(payload) {
    let response = await axiosInstance().post(`/auth/update_password`, payload);
    return response;
  },

  async VerifyToken(payload) {
    let response = await axiosInstance().post(`/auth/verify_token`, payload);
    return response;
  },

  async RegisterUser(payload) {
    let response = await axiosInstance().post(`/auth/register`, payload);
    return response;
  },

  async CreateProduct(payload) {
    let response = await axiosInstance().post("/product", payload);
    console.log(response);
    return response;
  },
};
